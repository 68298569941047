import {
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
  generate,
} from '@ant-design/colors';

const brand = generate('#1890ff');
brand.primary = brand[5];

const lightgrey = generate('#b0b0b0');
lightgrey.primary = lightgrey[5];

export {
  brand,
  lightgrey,

  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
}
